.mat-datepicker-popup {
    .mat-datepicker-content .mat-calendar {
        height: 365px;
    }

    .mat-calendar-controls {
        margin: 5px 4px 16px;

        .mdc-button,
        .mat-calendar-period-button {
            margin: 0;
            min-height: auto;

            &:hover {
                .mdc-button__label {
                    svg {
                        fill: $whiteColor;
                    }
                }
            }

            .mdc-button__label {
                svg {
                    fill: $mainColor;
                    transition: fill 0.3s linear;
                }
            }
        }
    }
}
