.custom-scrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $mainColor;
        border-radius: 2px;

        &:hover {
            background-color: $mainColorAccent;
        }
    }
}
