@use 'sass:map';

$grayColors: (
    0: hsl(0, 0%, 0%),
    4: hsl(0, 0%, 4%),
    5: hsl(0, 0%, 5%),
    6: hsl(0, 0%, 6%),
    10: hsl(0, 0%, 10%),
    12: hsl(0, 0%, 12%),
    17: hsl(0, 0%, 17%),
    20: hsl(0, 0%, 20%),
    22: hsl(0, 0%, 22%),
    24: hsl(0, 0%, 24%),
    25: hsl(0, 0%, 25%),
    30: hsl(0, 0%, 30%),
    35: hsl(0, 0%, 35%),
    40: hsl(0, 0%, 40%),
    50: hsl(0, 0%, 50%),
    60: hsl(0, 0%, 60%),
    70: hsl(0, 0%, 70%),
    80: hsl(0, 0%, 80%),
    87: hsl(0, 0%, 87%),
    90: hsl(0, 0%, 90%),
    92: hsl(0, 0%, 92%),
    94: hsl(0, 0%, 94%),
    95: hsl(0, 0%, 95%),
    96: hsl(0, 0%, 96%),
    98: hsl(0, 0%, 98%),
    99: hsl(0, 0%, 99%),
    100: hsl(0, 0%, 100%)
);

$blackColor: map.get($grayColors, 0);
$blackColorAlternate: map.get($grayColors, 5);
$grayColor: map.get($grayColors, 50);
$whiteColor: map.get($grayColors, 100);

$colorSaturation: 60%;

$blueColorHue: 225;
$blueColors: (
    50: hsl($blueColorHue, $colorSaturation, 95%),
    100: hsl($blueColorHue, $colorSaturation, 90%),
    150: hsl($blueColorHue, $colorSaturation, 85%),
    200: hsl($blueColorHue, $colorSaturation, 80%),
    250: hsl($blueColorHue, $colorSaturation, 85%),
    300: hsl($blueColorHue, $colorSaturation, 70%),
    350: hsl($blueColorHue, $colorSaturation, 65%),
    400: hsl($blueColorHue, $colorSaturation, 60%),
    450: hsl($blueColorHue, $colorSaturation, 55%),
    500: hsl($blueColorHue, $colorSaturation, 50%),
    550: hsl($blueColorHue, $colorSaturation, 45%),
    600: hsl($blueColorHue, $colorSaturation, 40%),
    650: hsl($blueColorHue, $colorSaturation, 35%),
    700: hsl($blueColorHue, $colorSaturation, 30%),
    750: hsl($blueColorHue, $colorSaturation, 25%),
    800: hsl($blueColorHue, $colorSaturation, 20%),
    850: hsl($blueColorHue, $colorSaturation, 15%),
    900: hsl($blueColorHue, $colorSaturation, 10%),
    950: hsl($blueColorHue, $colorSaturation, 5%),
    contrast: (
        50: $blackColor,
        100: $blackColor,
        200: $blackColor,
        300: $blackColor,
        400: $whiteColor,
        500: $whiteColor,
        600: $whiteColor,
        700: $whiteColor,
        800: $whiteColor,
        900: $whiteColor
    )
);

$redColorHue: 345;
$redColorSaturation: 100%;
$redColors: (
    50: hsl($redColorHue, $redColorSaturation, 95%),
    100: hsl($redColorHue, $redColorSaturation, 90%),
    200: hsl($redColorHue, $redColorSaturation, 80%),
    300: hsl($redColorHue, $redColorSaturation, 70%),
    400: hsl($redColorHue, $redColorSaturation, 60%),
    500: hsl($redColorHue, $redColorSaturation, 50%),
    600: hsl($redColorHue, $redColorSaturation, 40%),
    700: hsl($redColorHue, $redColorSaturation, 30%),
    800: hsl($redColorHue, $redColorSaturation, 20%),
    900: hsl($redColorHue, $redColorSaturation, 10%),
    contrast: (
        50: $blackColor,
        100: $blackColor,
        200: $blackColor,
        300: $blackColor,
        400: $whiteColor,
        500: $whiteColor,
        600: $whiteColor,
        700: $whiteColor,
        800: $whiteColor,
        900: $whiteColor
    )
);

$yellowColorHue: 45;
$yellowColorSaturation: 100%;
$yellowColors: (
    50: hsl($yellowColorHue, $yellowColorSaturation, 95%),
    100: hsl($yellowColorHue, $yellowColorSaturation, 90%),
    200: hsl($yellowColorHue, $yellowColorSaturation, 80%),
    300: hsl($yellowColorHue, $yellowColorSaturation, 70%),
    400: hsl($yellowColorHue, $yellowColorSaturation, 60%),
    500: hsl($yellowColorHue, $yellowColorSaturation, 50%),
    600: hsl($yellowColorHue, $yellowColorSaturation, 40%),
    700: hsl($yellowColorHue, $yellowColorSaturation, 30%),
    800: hsl($yellowColorHue, $yellowColorSaturation, 20%),
    900: hsl($yellowColorHue, $yellowColorSaturation, 10%),
    contrast: (
        50: $blackColor,
        100: $blackColor,
        200: $blackColor,
        300: $blackColor,
        400: $blackColor,
        500: $whiteColor,
        600: $whiteColor,
        700: $whiteColor,
        800: $whiteColor,
        900: $whiteColor
    )
);

$greenColorHue: 135;
$greenColors: (
    50: hsl($greenColorHue, $colorSaturation, 95%),
    100: hsl($greenColorHue, $colorSaturation, 90%),
    200: hsl($greenColorHue, $colorSaturation, 80%),
    300: hsl($greenColorHue, $colorSaturation, 70%),
    400: hsl($greenColorHue, $colorSaturation, 60%),
    500: hsl($greenColorHue, $colorSaturation, 50%),
    600: hsl($greenColorHue, $colorSaturation, 40%),
    700: hsl($greenColorHue, $colorSaturation, 30%),
    800: hsl($greenColorHue, $colorSaturation, 20%),
    900: hsl($greenColorHue, $colorSaturation, 10%),
    contrast: (
        50: $blackColor,
        100: $blackColor,
        200: $blackColor,
        300: $blackColor,
        400: $blackColor,
        500: $blackColor,
        600: $whiteColor,
        700: $whiteColor,
        800: $whiteColor,
        900: $whiteColor
    )
);

$palettes: (
    primary: (
        0: hsl($blueColorHue, $colorSaturation, 10%),
        10: hsl($blueColorHue, $colorSaturation, 40%),
        20: hsl($blueColorHue, $colorSaturation, 40%),
        25: hsl($blueColorHue, $colorSaturation, 40%),
        30: hsl($blueColorHue, $colorSaturation, 40%),
        35: hsl($blueColorHue, $colorSaturation, 40%),
        40: hsl($blueColorHue, $colorSaturation, 50%),
        50: hsl($blueColorHue, $colorSaturation, 50%),
        60: hsl($blueColorHue, $colorSaturation, 50%),
        70: hsl($blueColorHue, $colorSaturation, 70%),
        80: hsl($blueColorHue, $colorSaturation, 70%),
        90: hsl($blueColorHue, $colorSaturation, 70%),
        95: hsl($blueColorHue, $colorSaturation, 90%),
        98: hsl($blueColorHue, $colorSaturation, 90%),
        99: hsl($blueColorHue, $colorSaturation, 90%),
        100: hsl($blueColorHue, $colorSaturation, 90%)
    ),
    secondary: (
        0: hsl($blueColorHue, $colorSaturation, 0%),
        10: hsl($blueColorHue, $colorSaturation, 10%),
        20: hsl($blueColorHue, $colorSaturation, 20%),
        25: hsl($blueColorHue, $colorSaturation, 25%),
        30: hsl($blueColorHue, $colorSaturation, 30%),
        35: hsl($blueColorHue, $colorSaturation, 35%),
        40: hsl($blueColorHue, $colorSaturation, 40%),
        50: hsl($blueColorHue, $colorSaturation, 50%),
        60: hsl($blueColorHue, $colorSaturation, 60%),
        70: hsl($blueColorHue, $colorSaturation, 70%),
        80: hsl($blueColorHue, $colorSaturation, 80%),
        90: hsl($blueColorHue, $colorSaturation, 90%),
        95: hsl($blueColorHue, $colorSaturation, 95%),
        98: hsl($blueColorHue, $colorSaturation, 98%),
        99: hsl($blueColorHue, $colorSaturation, 99%),
        100: hsl($blueColorHue, $colorSaturation, 100%)
    ),
    tertiary: (
        0: hsl($blueColorHue, $colorSaturation, 0%),
        10: hsl($blueColorHue, $colorSaturation, 10%),
        20: hsl($blueColorHue, $colorSaturation, 20%),
        25: hsl($blueColorHue, $colorSaturation, 25%),
        30: hsl($blueColorHue, $colorSaturation, 30%),
        35: hsl($blueColorHue, $colorSaturation, 35%),
        40: hsl($blueColorHue, $colorSaturation, 40%),
        50: hsl($blueColorHue, $colorSaturation, 50%),
        60: hsl($blueColorHue, $colorSaturation, 60%),
        70: hsl($blueColorHue, $colorSaturation, 70%),
        80: hsl($blueColorHue, $colorSaturation, 80%),
        90: hsl($blueColorHue, $colorSaturation, 90%),
        95: hsl($blueColorHue, $colorSaturation, 95%),
        98: hsl($blueColorHue, $colorSaturation, 98%),
        99: hsl($blueColorHue, $colorSaturation, 99%),
        100: hsl($blueColorHue, $colorSaturation, 100%)
    ),
    neutral: (
        0: map.get($grayColors, 0),
        10: map.get($grayColors, 10),
        20: map.get($grayColors, 20),
        25: map.get($grayColors, 25),
        30: map.get($grayColors, 30),
        35: map.get($grayColors, 35),
        40: map.get($grayColors, 40),
        50: map.get($grayColors, 50),
        60: map.get($grayColors, 60),
        70: map.get($grayColors, 70),
        80: map.get($grayColors, 80),
        90: map.get($grayColors, 90),
        95: map.get($grayColors, 95),
        98: map.get($grayColors, 98),
        99: map.get($grayColors, 99),
        100: map.get($grayColors, 100),
        4: map.get($grayColors, 4),
        6: map.get($grayColors, 6),
        12: map.get($grayColors, 12),
        17: map.get($grayColors, 17),
        22: map.get($grayColors, 22),
        24: map.get($grayColors, 24),
        87: map.get($grayColors, 87),
        92: map.get($grayColors, 92),
        94: map.get($grayColors, 94),
        96: map.get($grayColors, 96)
    ),
    neutral-variant: (
        0: map.get($grayColors, 0),
        10: map.get($grayColors, 10),
        20: map.get($grayColors, 20),
        25: map.get($grayColors, 25),
        30: map.get($grayColors, 30),
        35: map.get($grayColors, 35),
        40: map.get($grayColors, 40),
        50: map.get($grayColors, 50),
        60: map.get($grayColors, 60),
        70: map.get($grayColors, 70),
        80: map.get($grayColors, 80),
        90: map.get($grayColors, 90),
        95: map.get($grayColors, 95),
        98: map.get($grayColors, 98),
        99: map.get($grayColors, 99),
        100: map.get($grayColors, 100)
    ),
    error: (
        0: hsl($redColorHue, $colorSaturation, 10%),
        10: hsl($redColorHue, $colorSaturation, 40%),
        20: hsl($redColorHue, $colorSaturation, 40%),
        25: hsl($redColorHue, $colorSaturation, 40%),
        30: hsl($redColorHue, $colorSaturation, 40%),
        35: hsl($redColorHue, $colorSaturation, 40%),
        40: hsl($redColorHue, $colorSaturation, 50%),
        50: hsl($redColorHue, $colorSaturation, 50%),
        60: hsl($redColorHue, $colorSaturation, 50%),
        70: hsl($redColorHue, $colorSaturation, 60%),
        80: hsl($redColorHue, $colorSaturation, 60%),
        90: hsl($redColorHue, $colorSaturation, 60%),
        95: hsl($redColorHue, $colorSaturation, 90%),
        98: hsl($redColorHue, $colorSaturation, 90%),
        99: hsl($redColorHue, $colorSaturation, 90%),
        100: hsl($redColorHue, $colorSaturation, 90%)
    )
);

$paletteRest: (
    secondary: map.get($palettes, secondary),
    neutral: map.get($palettes, neutral),
    neutral-variant: map.get($palettes, neutral-variant),
    error: map.get($palettes, error)
);
$palettePrimary: map.merge(map.get($palettes, primary), $paletteRest);
$paletteTertiary: map.merge(map.get($palettes, tertiary), $paletteRest);

$backgroundColor: var(--background-color);
$backgroundColorAlternate: var(--background-color-alternate);
$backgroundColorSolid: var(--background-color-solid);
$backgroundColorTransparent33: var(--background-color-solid-transparent-33);
$backgroundColorTransparent66: var(--background-color-solid-transparent-66);
$backgroundColorGray: var(--background-color-gray);
$fontColor: var(--font-color);

$mainColor: var(--main-color);
$mainColorAccent: var(--main-color-accent);
$mainColorBackground: var(--main-color-background);

$errorColor: var(--error-color);
$errorColorAccent: var(--error-color-accent);
$errorColorBackground: var(--error-color-background);

$warningColor: var(--warning-color);
$warningColorAccent: var(--warning-color-accent);
$warningColorBackground: var(--warning-color-background);

$successColor: var(--success-color);
$successColorAccent: var(--success-color-accent);
$successColorBackground: var(--success-color-background);

$menuColor1: var(--menu-color-1);
$menuColor2: var(--menu-color-2);
$menuColor3: var(--menu-color-3);
$menuColor4: var(--menu-color-4);

$menuColorHover: var(--menu-color-hover);
