@keyframes loading {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}

.loading-container {
    background-color: $backgroundColorAlternate;
    color: transparent;
}

.loading-animation {
    display: flex;
    position: relative;
    background-color: $backgroundColor;
    overflow: hidden;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.11);
    color: transparent;
    border-radius: 10px;

    &::after {
        display: block;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        transform: translateX(-100%);
        background: linear-gradient(90deg, transparent, rgba($grayColor, 0.35), transparent);
        animation: loading 1.5s infinite;
    }
}

p.loading-animation {
    height: 17px;

    @include rwd(laptop) {
        height: 19px;
    }
}
