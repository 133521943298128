mat-error {
    font-size: $fontSizeXS;
    line-height: 16px;
}

.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    form {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        max-width: 300px;
        width: 100%;

        @include rwd(tablet) {
            max-width: 400px;
        }

        .mat-mdc-form-field {
            margin-bottom: 10px;
            width: 100%;

            .mat-mdc-text-field-wrapper {
                background: none;
                padding: 0;

                .mat-mdc-form-field-focus-overlay {
                    background: none;
                }
            }

            &.one-color-white {
                .mdc-text-field--filled:not(.mdc-text-field--disabled) {
                    .mdc-line-ripple::before {
                        border-bottom-color: $grayColor;
                    }

                    .mdc-floating-label {
                        color: $grayColor;
                    }

                    .mdc-text-field__input {
                        color: $whiteColor;
                    }

                    &.mdc-text-field--focused .mdc-floating-label {
                        color: $mainColor;
                    }
                }

                .mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled) {
                    .mdc-floating-label,
                    .mdc-floating-label--float-above {
                        color: $errorColor;
                    }

                    .mdc-line-ripple::before {
                        border-bottom-color: $errorColor;
                    }
                }
            }
        }

        mat-checkbox {
            width: 100%;
            text-align: left;

            .mdc-checkbox {
                margin-left: -11px;
            }
        }

        .number-without-buttons {
            appearance: textfield;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }

        .submit-container {
            margin-top: 20px;
            align-self: center;
        }

        .text-error {
            text-align: center;
            margin-bottom: 10px;
        }

        .radio-buttons {
            margin-bottom: 10px;

            .mat-mdc-radio-button {
                .mdc-form-field {
                    width: 100%;

                    & > label {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.mat-mdc-select.flag-select {
    mat-select-trigger {
        position: relative;
        padding-left: 36px;

        img {
            position: absolute;
            top: 0;
            left: 6px;
            bottom: 0;
            margin: auto;
            width: 24px;
        }
    }

    &.small {
        mat-select-trigger {
            padding-left: 28px;
            font-size: $fontSizeS;

            img {
                max-width: 20px;
                left: 4px;
            }
        }
    }
}

mat-pseudo-checkbox.mat-pseudo-checkbox {
    display: none;
}

.cdk-overlay-container {
    .mdc-menu-surface.mat-mdc-select-panel {
        padding: 0;

        .mat-mdc-option {
            position: relative;
            padding-left: 28px;
            min-height: 40px;
            font-size: $fontSizeS;

            img {
                position: absolute;
                top: 0;
                left: 4px;
                bottom: 0;
                margin: auto;
                max-width: 20px;
            }

            .country-phone {
                &::before {
                    content: '+';
                }
            }
        }
    }
}

.mat-mdc-slide-toggle.mat-primary {
    label {
        padding: 0;
    }
}

.mdc-list-item__primary-text {
    width: 100%;
}
