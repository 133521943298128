$underlineSize: 4px;
$transitionTime: 0.3s;

@mixin underline {
    content: '';
    position: absolute;
    bottom: $underlineSize * -1;
    left: $underlineSize * -1;
    background-color: $mainColor;
    transform-origin: 0 0;
    transition: transform $transitionTime linear;
}

.section-header {
    font-size: $fontSizeL;
    margin: 0 auto 30px;
    padding: 0 10px;
    position: relative;
    width: fit-content;
    line-height: 35px;

    @include rwd(tablet) {
        font-size: $fontSizeXL;
        margin-bottom: 45px;

        &-styled {
            &::after {
                @include underline;
                width: $underlineSize;
                height: 20px;
                transform: scaleY(0);
            }

            &::before {
                @include underline;
                width: 80px;
                height: $underlineSize;
                transform: scaleX(0);
                transition-delay: $transitionTime;
            }

            &.scroll-animation-active {
                &::after {
                    transform: scaleY(1);
                }

                &::before {
                    transform: scaleX(1);
                }
            }
        }
    }

    @include rwd(laptop) {
        font-size: $fontSizeXXL;
        margin-bottom: 60px;
    }
}
