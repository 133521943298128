@use 'sass:map';
@use '@angular/material' as mat;
@include mat.core();

$lightThemeBackgroundColor: map.get($grayColors, 90);
$lightThemeBackgroundColorAlternate: map.get($grayColors, 95);
$lightThemeBackgroundColorSolid: map.get($grayColors, 100);
$lightThemeBackgroundColorGray: map.get($grayColors, 80);
$lightThemeFontColor: map.get($grayColors, 0);

$lightThemeNormalLightness: 600;
$lightThemeMainLightness: 500;
$lightThemeWarnLightness: 500;
$lightThemeAccentLightness: $lightThemeNormalLightness + 100;
$lightThemeBackgroundLightness: 50;

$lightThemeMainPalette: $blueColors;
$lightThemeErrorPalette: $redColors;
$lightThemeWarningPalette: $yellowColors;
$lightThemeSuccessPalette: $greenColors;

$lightTheme: mat.define-theme(
    (
        color: (
            theme-type: light,
            primary: $palettePrimary,
            tertiary: $paletteTertiary
        ),
        typography: (
            brand-family: 'Roboto',
            bold-weight: 400
        )
    )
);

$lightThemeM2: mat.m2-define-light-theme(
    (
        color: (
            primary:
                mat.m2-define-palette(
                    $lightThemeMainPalette,
                    $lightThemeMainLightness,
                    $lightThemeMainLightness + 100,
                    $lightThemeMainLightness - 100
                ),
            accent:
                mat.m2-define-palette(
                    $lightThemeSuccessPalette,
                    $lightThemeNormalLightness,
                    $lightThemeNormalLightness + 100,
                    $lightThemeNormalLightness - 100
                ),
            warn:
                mat.m2-define-palette(
                    $lightThemeErrorPalette,
                    $lightThemeNormalLightness,
                    $lightThemeNormalLightness + 100,
                    $lightThemeNormalLightness - 100
                )
        )
    )
);

body {
    --background-color: #{$lightThemeBackgroundColor};
    --background-color-alternate: #{$lightThemeBackgroundColorAlternate};
    --background-color-solid: #{$lightThemeBackgroundColorSolid};
    --background-color-solid-transparent-33: #{rgba($lightThemeBackgroundColorSolid, 0.33)};
    --background-color-solid-transparent-66: #{rgba($lightThemeBackgroundColorSolid, 0.66)};
    --background-color-gray: #{$lightThemeBackgroundColorGray};
    --font-color: #{$lightThemeFontColor};

    --main-color: #{map.get($lightThemeMainPalette, $lightThemeMainLightness)};
    --main-color-accent: #{map.get($lightThemeMainPalette, $lightThemeMainLightness + 100)};
    --main-color-background: #{map.get($lightThemeMainPalette, $lightThemeBackgroundLightness)};

    --error-color: #{map.get($lightThemeErrorPalette, $lightThemeNormalLightness)};
    --error-color-accent: #{map.get($lightThemeErrorPalette, $lightThemeAccentLightness)};
    --error-color-background: #{map.get($lightThemeErrorPalette, $lightThemeBackgroundLightness)};

    --warning-color: #{map.get($lightThemeWarningPalette, $lightThemeWarnLightness)};
    --warning-color-accent: #{map.get($lightThemeWarningPalette, $lightThemeAccentLightness)};
    --warning-color-background: #{map.get($lightThemeWarningPalette, $lightThemeBackgroundLightness)};

    --success-color: #{map.get($lightThemeSuccessPalette, $lightThemeNormalLightness)};
    --success-color-accent: #{map.get($lightThemeSuccessPalette, $lightThemeAccentLightness)};
    --success-color-background: #{map.get($lightThemeSuccessPalette, $lightThemeBackgroundLightness)};

    --menu-color-1: #{map.get($darkThemeMainPalette, 350)};
    --menu-color-2: #{map.get($darkThemeMainPalette, 400)};
    --menu-color-3: #{map.get($darkThemeMainPalette, 450)};
    --menu-color-4: #{map.get($darkThemeMainPalette, 500)};

    --menu-color-hover: hsl(205deg, 60%, 50%);

    @include mat.all-component-themes($lightTheme);
    @include mat.slide-toggle-theme($lightThemeM2);
}
