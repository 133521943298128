.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $blackColor;
    opacity: 0;
    pointer-events: none;
}

body {
    &.popup-opened {
        overflow: hidden;

        .overlay {
            opacity: 0.5;
            pointer-events: all;
        }
    }
}

.cdk-overlay-container {
    z-index: 80;
}
