.aspect-ratio-box {
    overflow: hidden;
    padding-top: 56.25%;
    position: relative;
    width: 100%;

    > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
