.document {
    padding: 140px 10px 60px;
    text-align: justify;
    background-color: $backgroundColorSolid;

    @include rwd(laptop) {
        background-color: transparent;
    }

    &-container {
        background-color: $backgroundColorSolid;
        max-width: 1080px;
        padding: 40px;
        margin: 0 auto;
    }

    h2 {
        font-size: $fontSizeL;

        @include rwd(tablet) {
            font-size: $fontSizeXL;
        }
    }

    h3 {
        text-align: left;
        font-size: $fontSizeM;

        @include rwd(tablet) {
            font-size: $fontSizeL;
        }
    }

    ul,
    ol {
        margin: 0 0 20px 15px;

        @include rwd(tablet) {
            margin-left: 30px;
        }

        li {
            margin-bottom: 10px;
            line-height: 20px;
            font-size: $fontSizeS;

            @include rwd(tablet) {
                font-size: $fontSizeM;
            }
        }

        ul,
        ol {
            margin-top: 10px;
        }
    }

    dl {
        margin: 0 0 20px 15px;

        @include rwd(tablet) {
            margin-left: 30px;
        }

        dt {
            font-size: $fontSizeS;
            margin-bottom: 10px;
            font-weight: 700;

            @include rwd(tablet) {
                font-size: $fontSizeM;
            }
        }

        dd {
            font-size: $fontSizeS;
            margin: 0 0 10px 10px;

            @include rwd(tablet) {
                font-size: $fontSizeM;
            }
        }
    }

    a {
        text-decoration: none;
        color: $fontColor;

        &:hover,
        &:visited {
            color: $fontColor;
        }

        mat-icon {
            font-size: $iconSizeXXS;
            height: 15px;
            width: 15px;
        }
    }
}
