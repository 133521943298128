@keyframes blink {
    0%,
    100% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
}

.cursor-typing {
    &::after {
        content: '_';
        animation: blink 0.5s steps(1, end) infinite;
    }
}
