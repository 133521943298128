mat-dialog-container {
    .container {
        background-color: $backgroundColorSolid;
        min-width: 300px;

        @include rwd(tablet) {
            min-width: 400px;
        }

        @include rwd(laptop) {
            min-width: 500px;
        }

        .dialog-subtitle {
            font-size: $fontSizeS;
            margin-bottom: 20px;
            line-height: $fontSizeM;

            @include rwd(laptop) {
                font-size: $fontSizeM;
                line-height: $fontSizeL;
            }
        }

        .form-container form {
            @include rwd(laptop) {
                max-width: 500px;
            }
        }

        .mat-mdc-dialog-actions {
            justify-content: center;
            gap: 20px;
            margin-top: 10px;

            button {
                margin: 0;
            }
        }
    }
}
