$borderDown: transparent transparent $backgroundColorSolid transparent;
$borderRight: transparent transparent transparent $backgroundColorSolid;
$borderTop: $backgroundColorSolid transparent transparent transparent;

@mixin toolTip($transform: 10px) {
    background-color: $backgroundColorSolid;
    color: $fontColor;
    font-size: $fontSizeXS;
    padding: 10px;
    overflow: visible;
    transform: $transform;
}

@mixin toolTipAfter($marginLeft: -10px, $border, $left: 50%, $bottom) {
    content: '';
    position: absolute;
    bottom: $bottom;
    left: $left;
    margin-left: $marginLeft;
    border-width: 10px;
    border-style: solid;
    border-color: $border;
}

.cdk-overlay-container {
    .mat-mdc-tooltip-panel {
        pointer-events: none;

        .mdc-tooltip {
            .mdc-tooltip__surface {
                @include toolTip();

                &::after {
                    @include toolTipAfter($border: $borderDown, $bottom: 100%);
                }
            }
        }
    }

    .mat-mdc-tooltip-panel-left {
        .mdc-tooltip {
            .mdc-tooltip__surface {
                @include toolTip($transform: 0);

                &::after {
                    @include toolTipAfter($marginLeft: -2px, $border: $borderRight, $left: 100%, $bottom: 20%);
                }
            }
        }
    }

    .mat-mdc-tooltip-panel-above {
        .mdc-tooltip {
            .mdc-tooltip__surface {
                @include toolTip();

                &::after {
                    @include toolTipAfter($border: $borderTop, $bottom: -30%);
                }
            }
        }
    }
}
