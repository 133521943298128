@use 'sass:map';
@use '@angular/material' as mat;
@include mat.core();

$darkThemeBackgroundColor: map.get($grayColors, 10);
$darkThemeBackgroundColorAlternate: map.get($grayColors, 5);
$darkThemeBackgroundColorSolid: map.get($grayColors, 0);
$darkThemeBackgroundColorGray: map.get($grayColors, 20);
$darkThemeFontColor: map.get($grayColors, 100);

$darkThemeNormalLightness: 500;
$darkThemeAccentLightness: $darkThemeNormalLightness - 200;
$darkThemeBackgroundLightness: 900;

$darkThemeMainPalette: $blueColors;
$darkThemeErrorPalette: $redColors;
$darkThemeWarningPalette: $yellowColors;
$darkThemeSuccessPalette: $greenColors;

$darkTheme: mat.define-theme(
    (
        color: (
            theme-type: dark,
            primary: $palettePrimary,
            tertiary: $paletteTertiary
        ),
        typography: (
            brand-family: 'Roboto',
            bold-weight: 400
        )
    )
);

$darkThemeM2: mat.m2-define-dark-theme(
    (
        color: (
            primary:
                mat.m2-define-palette(
                    $darkThemeMainPalette,
                    $darkThemeNormalLightness,
                    $darkThemeNormalLightness + 100,
                    $darkThemeNormalLightness - 100
                ),
            accent:
                mat.m2-define-palette(
                    $darkThemeSuccessPalette,
                    $darkThemeNormalLightness,
                    $darkThemeNormalLightness + 100,
                    $darkThemeNormalLightness - 100
                ),
            warn:
                mat.m2-define-palette(
                    $darkThemeErrorPalette,
                    $darkThemeNormalLightness,
                    $darkThemeNormalLightness + 100,
                    $darkThemeNormalLightness - 100
                )
        )
    )
);

body.dark-theme {
    --background-color: #{$darkThemeBackgroundColor};
    --background-color-alternate: #{$darkThemeBackgroundColorAlternate};
    --background-color-solid: #{$darkThemeBackgroundColorSolid};
    --background-color-solid-transparent-33: #{rgba($darkThemeBackgroundColorSolid, 0.2)};
    --background-color-solid-transparent-66: #{rgba($darkThemeBackgroundColorSolid, 0.5)};
    --background-color-gray: #{$darkThemeBackgroundColorGray};
    --font-color: #{$darkThemeFontColor};

    --main-color: #{map.get($darkThemeMainPalette, $darkThemeNormalLightness)};
    --main-color-accent: #{map.get($darkThemeMainPalette, $darkThemeAccentLightness)};
    --main-color-background: #{map.get($darkThemeMainPalette, $darkThemeBackgroundLightness)};

    --error-color: #{map.get($darkThemeErrorPalette, $darkThemeNormalLightness)};
    --error-color-accent: #{map.get($darkThemeErrorPalette, $darkThemeAccentLightness)};
    --error-color-background: #{map.get($darkThemeErrorPalette, $darkThemeBackgroundLightness)};

    --warning-color: #{map.get($darkThemeWarningPalette, $darkThemeNormalLightness)};
    --warning-color-accent: #{map.get($darkThemeWarningPalette, $darkThemeAccentLightness)};
    --warning-color-background: #{map.get($darkThemeWarningPalette, $darkThemeBackgroundLightness)};

    --success-color: #{map.get($darkThemeSuccessPalette, $darkThemeNormalLightness)};
    --success-color-accent: #{map.get($darkThemeSuccessPalette, $darkThemeAccentLightness)};
    --success-color-background: #{map.get($darkThemeSuccessPalette, $darkThemeBackgroundLightness)};

    --menu-color-1: #{map.get($darkThemeMainPalette, 750)};
    --menu-color-2: #{map.get($darkThemeMainPalette, 700)};
    --menu-color-3: #{map.get($darkThemeMainPalette, 650)};
    --menu-color-4: #{map.get($darkThemeMainPalette, 600)};

    --menu-color-hover: hsl(205deg, 60%, 50%);

    @include mat.all-component-colors($darkTheme);
    @include mat.slide-toggle-color($darkThemeM2);
}
