.front-scroll-animation.no-visible {
    opacity: 0;

    * {
        opacity: 0;
    }

    &.scroll-animation-active {
        opacity: 1;

        * {
            opacity: 1;
        }
    }
}
