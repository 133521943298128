.text-main {
    color: $mainColor;
}

.text-error {
    color: $errorColor;
}

.text-warning {
    color: $warningColor;
}

.text-success {
    color: $successColor;
}

.text-lack {
    color: $grayColor;
    text-align: center;
}

.text-special {
    letter-spacing: 1px;
    font-family: $caveatFontFamily;
    color: $mainColor;
    text-shadow: 0 0 1px $mainColor;
    font-size: $fontSizeXL;

    @include rwd(laptop) {
        font-size: $fontSizeXXL;
    }
}

.text-center {
    text-align: center;
}
